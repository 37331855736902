
import { WorkOrderStatusConfigEntityModel, WorkOrderStatusConfigQueryModel } from '@/entity-model/work-order-status-config-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/process/definition/status`;

class WorkOrderStatusConfigService implements ICRUDQ<WorkOrderStatusConfigEntityModel, WorkOrderStatusConfigQueryModel> {
    async create(model: WorkOrderStatusConfigEntityModel):Promise<WorkOrderStatusConfigEntityModel> {
        const url = `${URL_PATH}/addProcessStatus/${model.processKey}`;
        // const params = model.toService();
        const res = await post(url);
        return res;
    }

    async retrieve(modelId: string):Promise<WorkOrderStatusConfigEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new WorkOrderStatusConfigEntityModel().toModel(res);
    }

    async update(model: WorkOrderStatusConfigEntityModel):Promise<WorkOrderStatusConfigEntityModel> {
        const url = `${URL_PATH}/saveFlowNodeStatus/${model.id}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: WorkOrderStatusConfigEntityModel):Promise<WorkOrderStatusConfigEntityModel> {
        const url = `${URL_PATH}/deleteProcessStatus/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderStatusConfigQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/listTreeifyProcessStatus`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        return _.map(res, item => item = new WorkOrderStatusConfigEntityModel().toModel(item));
    }
}

export default new WorkOrderStatusConfigService();
