


















































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import TableTreeComponent from '@/mixins/table-tree-component';
import { WorkOrderStatusConfigEntityModel, WorkOrderStatusConfigQueryModel } from '@/entity-model/work-order-status-config-entity';
import WorkOrderStatusConfigService from '@/service/work-order-status-config';

@Component
export default class WorkOrderStatusConfigComponent extends Mixins(TableDialogFormComponent, TableTreeComponent) {
    WorkOrderStatusConfigEntityModel = WorkOrderStatusConfigEntityModel;

    expandedRowKeys: Array<string>;

    created() {
        this.initTable({
            service: WorkOrderStatusConfigService,
            queryModel: new WorkOrderStatusConfigQueryModel(),
            tableColumns: WorkOrderStatusConfigEntityModel.getTableColumns()
        });

        this.getList();
    }
}
