import { workOrderProcessGroupTypeFilter, workOrderProcessGroupTypeList } from '@/filter/workorder-process-group-type';
import { BaseEntityModel } from '@/model/base-model';
import { WorkOrderProcessGroupType } from '@/model/enum';
import { FormControl, FormControlType, FormControlOptionModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import CommonService from '@/service/common';

export class WorkOrderStatusConfigEntityModel extends BaseEntityModel {
    @FormControl({
        label: '名称',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAllProcess,
        readonly: true,
        required: true
    } as FormControlOptionModel)
    processKey: string = undefined;
    processName: string = undefined;
    nodeName: string = undefined;

    @FormControl({
        label: '分组',
        type: FormControlType.SELECT,
        options: workOrderProcessGroupTypeList,
        required: true,
        invisibleFunction: (model: WorkOrderStatusConfigEntityModel) => { return !model.id; }
    } as FormControlOptionModel)
    statusGroupType: WorkOrderProcessGroupType = undefined;

    isStatus: boolean = false;

    get DisplayName() {
        return this.processName || this.nodeName;
    }

    get hasChildren() {
        return this.children && this.children.length > 0;
    }

    children: Array<WorkOrderStatusConfigEntityModel> = undefined;
    expand: boolean = false;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'DisplayName',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '分组',
                dataIndex: 'statusGroupType',
                customRender: (text, record, index) => {
                    return workOrderProcessGroupTypeFilter(record.statusGroupType);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (_.get(json, 'children')) {
            this.children = _.map(_.get(json, 'children'), item => new WorkOrderStatusConfigEntityModel().toModel(Object.assign({ processKey: this.processKey, isStatus: true }, item)));
        }
        return this;
    }

    toService() {
        // return {
        //     id: this.id,
        //     // processKey: this.processKey,
        //     statusGroup: this.group
        // };
        return this.statusGroupType;
    }
}

export class WorkOrderStatusConfigQueryModel extends QueryPageModel {
    name: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
